<template>
  <mobile-screen :header="true" screen-class="profile-screen">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="applications-header">
        <template v-slot:left>
          <router-link :to="{ name: 'r_translations' }">
            <svg-icon icon="arrow-left"></svg-icon>
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("translations", "sections", "title") }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper applications-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('translations', 'sections', 'search')
            "
          />
          <div class="icon search"><svg-icon icon="lense"></svg-icon></div>
        </div>
      </section>
    </template>
    <ul class="named-list" v-if="listOfSections && listOfSections.length">
      <li class="name-wrapper">
        <p class="tiny-label bg">
          {{ displayLabelName("translations", "sections", "label") }}
        </p>
        <ul class="bordered-list">
          <li class="list-item" v-for="sec in listOfSections" :key="sec.id">
            <div class="checkbox tiny block">
              <input
                type="radio"
                :id="`section${sec.id}`"
                name="section"
                :checked="sec.id === selectedSection"
                :value="sec.id"
                @change="selectSection(sec.id)"
              />
              <label :for="`section${sec.id}`">
                <svg-icon icon="checkmark-strait"></svg-icon>
                {{ sec.name }}</label
              >
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :disabled="!selectedSection || processing"
              @click="selectSingleSection"
            >
              {{ displayLabelName("translations", "sections", "select") }}
            </button>
          </li>
          <li class="action">
            <button
              class="action-btn"
              :disabled="!selectedSection || processing"
              @click="goToPages"
            >
              {{ displayLabelName("translations", "sections", "open") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import translationsMixin from "@/services/mixins/translations/translations-mixin";
import { mapActions, mapState } from "vuex";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";

export default {
  name: "Sections",
  data() {
    return {
      selectedSection: 0,
      processing: false,
      show: false,
      searchQuery: ""
    };
  },
  mounted() {
    if (this.selectedLanguage) {
      this.getSections(this.selectedLanguage.locale);
    }
  },
  computed: {
    ...mapState("translation", ["sections"]),
    listOfSections() {
      const query = this.searchQuery;
      if (this.sections && this.sections.data && this.sections.data.length) {
        if (query && query.length > 1) {
          return this.sections.data.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.sections.data;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("translation", [
      "getSections",
      "setSelectedSection",
      "getPages"
    ]),
    selectSection(id) {
      this.selectedSection = id;
      this.getPages({ sectionId: id, lang: this.selectedLanguage.locale });
    },
    selectSingleSection() {
      this.setSelectedSection(
        this.sections.data.filter(item => item.id === this.selectedSection)
      );
      this.$router.push({ name: "r_selected-section" });
    },
    goToPages() {
      this.$router.push({ name: "r_translations-select-page" });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  mixins: [translationsMixin]
};
</script>
